import React from "react";
import { Breadcrumb, Container, CompanyContents } from "../../components";
import Seo from "../../components/Seo";

const CompanyPage = () => {
  return (
    <Container noAnimate="true">
      <Seo
        title="会社情報"
        description="TKCホールディングの会社情報ページです。会社概要、代表メッセージ、役員紹介、カルチャー紹介、アクセス情報などをご覧いただけます。"
      />
      <CompanyContents />
      <Breadcrumb currentPage="Company" currentSlug="/company" />
    </Container>
  );
};

export default CompanyPage;
